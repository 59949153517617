import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const ctrHeader = ({ data, setActiveControl }) => {

    return(
        <header className="header container-header full-width position-sticky sticky-top" >
            <div className="row simpleheader">
                <div className="col-md-4">
                    <img src={"/images/uec-logo-horizontal.svg"}></img>
                </div>
                <div className="col-md-4 ">
                <h1 id="ctrTitle" title="BMX Freestyle Web Manager by STS" > 2024 UEC BMX FREESTYLE PARK EUROPEAN CHAMPIONSHIPS</h1>
                </div>
                <div className="col-md-4" >
                    <div className="">
                <Navbar className=' link-dark d-none' expand="lg" >
                    <Navbar.Toggle aria-controls="basic-navbar-nav link-dark" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => setActiveControl('control1')}>Live</Nav.Link>
                        <NavDropdown title="Elite Women" id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => setActiveControl('control2')}>Qualification</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setActiveControl('control3')}>Final</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Elite Men" id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={() => setActiveControl('control4')}>Qualification</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setActiveControl('control5')}>Final</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    </Navbar.Collapse>
                </Navbar>
                </div>
                </div>
            </div>
            <div className="row d-none">
                <div className="col-md-4">
                    <h2 id="ctrTitle" title="BMX Freestyle Web Manager by STS" > { data.racename } { data.phase } Run { data.run }</h2>
                </div>
            </div>
        </header>
    );
};

export default ctrHeader;