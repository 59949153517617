import React, { useEffect, useState } from 'react';
//import io from 'socket.io-client';
import axios from 'axios';
import DataTable from './components/DataTable';
import CtrHeader from './components/ucHeader';
import CurrentRider from './components/ucCurrentRider';
import CtrWomensQual from './components/ucWomensQual';
import CtrMensQual from './components/ucMensQual';
import CtrWomensFinal from './components/ucWomensFNL';
import CtrMensFinal from './components/ucMensFNL';


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const apiUrl = process.env.REACT_APP_API_URL;
const bmfcrud = '/bmfxcrud';//process.env.REACT_CRUD_URL;
//const socket = io('https://ws.cyclingfreestyle.com');

const App = () => {

//    axios.get('http://10.0.0.2/bmfwm/bmfxcrud/tabletest.php')

const [dataAA, setDataAA] = useState([]);
const [changedId, setChangedId] = useState(null);
const [currentRidersCollection,setcurrentRidersCollection] = useState([]);
const [activeControl, setActiveControl] = useState('control1');

useEffect(() => {

  const fetchData = async () => {
    try {
      const response = await axios.post(apiUrl + bmfcrud + '/getCurrentSetting.php');
      try {
        setcurrentRidersCollection(response.data);
        setChangedId(response.data.bib);
        const response2 = await axios.post(apiUrl + bmfcrud + '/getRidersAtRacePhaseGroup.php',{
          race: response.data.parentrace, // Reemplaza 'valorNombre' con el valor que necesites
          phase: response.data.phase // Reemplaza 'valorId' con el valor que necesites
        });
        setDataAA(response2.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };  

  //fetchData();

  //const intervalId = setInterval(fetchData, 60000);

  // Limpiar el intervalo cuando el componente se desmonte
  //return () => clearInterval(intervalId);
 /* 
  // Cargar los datos iniciales
    axios.get(apiUrl + bmfcrud + '/getCurrentSetting.php')
    .then(response => {
      setcurrentRidersCollection(response.data);
      setChangedId(response.data.bib);
          axios.post(apiUrl + bmfcrud + '/getRidersAtRacePhaseGroup.php', new URLSearchParams({
            race: response.data.parentrace, // Reemplaza 'valorNombre' con el valor que necesites
            phase: response.data.phase // Reemplaza 'valorId' con el valor que necesites
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          } )
          .then(response => {
            setDataAA(response.data);
          })
          .catch(error => {
            console.error('Error al obtener los datos:', error);
          });
    })
    .catch(error => {
      console.error('Error al obtener los datos:', error);
    });

    socket.on('message', async () => {
      try {
        const response = await fetch(apiUrl + bmfcrud + '/getCurrentSetting.php');
        const data = await response.json();
        setcurrentRidersCollection(data);
        setChangedId(data.bib);
        axios.post(apiUrl + '/bmfwm/bmfxcrud/getRidersAtRacePhaseGroup.php', new URLSearchParams({
          race: data.parentrace, // Reemplaza 'valorNombre' con el valor que necesites
          phase: data.phase // Reemplaza 'valorId' con el valor que necesites
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        } )
        .then(response => {
          setDataAA(response.data);
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    });

    // Conectar al socket y escuchar las actualizaciones
    socket.on('connect', () => {
      console.log('Conectado al servidor de WebSocket');
    });

    socket.on('updateData', (newData) => {
      console.log('Datos recibidos del servidor:', newData);
      setDataAA(newData);
    });

    socket.on('documentChanged', (change) => {
      console.log('Documento cambiado:', change);
      //setChanges((prevChanges) => [...prevChanges, change]);
      if (change && change._id) {
        //setChangedId(change.documentKey._id);
        reloadData();
      } else {
        console.error('El cambio no contiene un campo _id válido:', change);
      }
    });

    socket.on('disconnect', () => {
      console.log('Desconectado del servidor de WebSocket');
    });

    // Limpiar la conexión cuando el componente se desmonta
    return () => {
      socket.off('updateData');
    };


    //Respaldo result live
    <div className="App">
      <CtrHeader data={currentRidersCollection} setActiveControl={setActiveControl} />
      { (activeControl === 'control1' && Array.isArray(dataAA) ) && <div className="row "> <div className="col-md-3"> <CurrentRider data={currentRidersCollection} />  </div>  <div className="col-md-9"> <DataTable data={dataAA} changedId={changedId} /> </div> </div>}
      { (activeControl === 'control1' && !Array.isArray(dataAA) ) && <div className="row "> <div className="col-md-3"> <CurrentRider data={currentRidersCollection} />  </div>  </div>}
      { activeControl === 'control2' &&  <div> <CtrWomensQual /> </div> }
      { activeControl === 'control3' &&  <div> <CtrWomensFinal /> </div> }
      { activeControl === 'control4' && <div> <CtrMensQual /> </div>}
      { activeControl === 'control5' && <div> <CtrMensFinal /> </div>}
    </div>

    */
}, []);


  const reloadData = () => {
    // Aquí puedes hacer una solicitud al servidor para obtener los datos actualizados
    fetch(apiUrl + bmfcrud + '/tabletest.php')
      .then(response => response.json())
      .then(newData => setDataAA(newData))
      .catch(error => console.error('Error al recargar los datos:', error));
  };

  return (
    <div className="App">
      <CtrHeader data={currentRidersCollection} setActiveControl={setActiveControl} />
      <div className="row "> 
        <div className="col-md-6"> 
        <CtrWomensQual />
       </div>
        <div className="col-md-6">
          <CtrMensQual />
        </div>
      </div>      
      <div className="row "> 
        <div className="col-md-6"> 
          <CtrWomensFinal />
        </div>
        <div className="col-md-6">
        <CtrMensFinal />
        </div>
      </div>      
    </div>
  );
} 

export default App;
