import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const ctrMensQual = ({ data, setActiveControl }) => {

    return(
        <div>
        <div className="row">
            <div className="col-md-12">
                <h2>Men's Qualification</h2>
            </div>
        </div>        

        <div className="row">
            <div className="col-md-12">
                <a href="/files/MenQUALstartlist.pdf" className="input-group-text bg-success text-white"><FontAwesomeIcon icon={faFilePdf} target="_blanck" /> StartList</a><br></br>
                <a href="/files/MenQUALresults.pdf" className="input-group-text bg-secondary text-white"><FontAwesomeIcon icon={faFilePdf} target="_blanck" /> Results</a><br></br>
            </div>
        </div>
        </div>
    );
};

export default ctrMensQual;