import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const ctrMensFinal = ({ data, setActiveControl }) => {

    return(
        <div>
        <div className="row">
            <div className="col-md-12">
                <h2>Men's Final</h2>
            </div>
        </div>        
        <div className="row">
        <div className="col-md-12">
            <a href="/files/MensFNLstartlist.pdf" className="input-group-text bg-success text-white"><FontAwesomeIcon icon={faFilePdf} target="_blanck" /> StartList</a>
            <br />
            <a href="/files/MensFNLresults.pdf" className="input-group-text bg-secondary text-white"><FontAwesomeIcon icon={faFilePdf} target="_blanck" /> Results</a>
            </div>
        </div>
        </div>
    );
};

export default ctrMensFinal;